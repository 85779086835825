import React from "react";

const Footer = (props) => {
  return (
    <>
      <div className="container-fluid mt-5">
        <div className="row bg-primary py-1">
          <div className="col-sm-12">
            <p className="text-uppercase mb-0 text-center text-white">

          
            <small className="text-captilize">Copyright © 2024 . All rights reserved.</small>
          
             
             
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
