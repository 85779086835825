import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

const EmpCreate = () => {
    const [name, setName] = useState("");
    const [designation, setDesig] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [active, setActive] = useState(true);
    const [validation, setValidation] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setValidation(true);
        const result = { name, designation, email, phone, active };
        try {
            const response = await axios.post(
                "https://api.jsonbin.io/v3/b/662650e2e41b4d34e4e872c4/records",
                result,
                {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Master-Key": "$2a$10$wvc66GTDgrTaaR/ro4xGDuVon/g0EE7rWbLmqqBJ/Zp6zagvR7XGG",
                        "X-Access-Key": "$2a$10$u6RhiQQVn4VIvbnn8ild0.fDV/QPWDrM46pKMYnxgZYOhUKyI4eem",
                        "Access-Key-Id": "66264e96acd3cb34a83c9c97"
                    },
                }
            );
            if (response.status === 200 || response.status === 201) {
                alert("Data Saved");
                navigate("/");
            } else {
                throw new Error(`Failed to Save Data: ${response.statusText}`);
            }
        } catch (error) {
            console.error("Error:", error.message);
        }
    };

    return (
        <>
            <Header title="Create Employee" />
            <form onSubmit={handleSubmit} className="mt-3">
                <div className="container">
                    <div className="col-sm-6 offset-3 bg-light-grey p-5 border border-secondary pb-3">
                        {/* Form Fields */}
                    </div>
                </div>
            </form>
            <Footer />
        </>
    );
};

export default EmpCreate;
