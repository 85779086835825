import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "./Header";
import Footer from "./Footer";
import DataTable from "react-data-table-component";

const EmployListing = () => {
  const [loading, setLoading] = useState(true);
  const [empdata, setEmpData] = useState([]);
  const [search, setSearch] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch("https://api.jsonbin.io/v3/b/662650e2e41b4d34e4e872c4/records", {
          headers: {
            "X-Master-Key": "$2a$10$wvc66GTDgrTaaR/ro4xGDuVon/g0EE7rWbLmqqBJ/Zp6zagvR7XGG",
            "X-Access-Key": "$2a$10$u6RhiQQVn4VIvbnn8ild0.fDV/QPWDrM46pKMYnxgZYOhUKyI4eem",
            "Access-Key-Id": "66264e96acd3cb34a83c9c97"
          },
        });
        const data = await response.json();
        setEmpData(data.record);
      } catch (error) {
        console.log(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const removeFunction = async (id) => {
    if (window.confirm("Do you want to remove?")) {
      try {
        const response = await fetch(`https://api.jsonbin.io/v3/b/662650e2e41b4d34e4e872c4/records/${id}`, {
          method: "DELETE",
          headers: {
            "X-Master-Key": "$2a$10$wvc66GTDgrTaaR/ro4xGDuVon/g0EE7rWbLmqqBJ/Zp6zagvR7XGG",
            "X-Access-Key": "$2a$10$u6RhiQQVn4VIvbnn8ild0.fDV/QPWDrM46pKMYnxgZYOhUKyI4eem",
            "Access-Key-Id": "66264e96acd3cb34a83c9c97"
          },
        });

        if (response.ok) {
          alert(`${id} - Record Deleted`);
          const updatedData = empdata.filter((emp) => emp.id !== id);
          setEmpData(updatedData);
        } else {
          throw new Error(`Failed to Delete Record: ${response.statusText}`);
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };

  const columns = [
    {
      name: "Name",
      selector: "name",
      sortable: true,
    },
    {
      name: "Designation",
      selector: "designation",
      sortable: true,
    },
    {
      name: "Email",
      selector: "email",
    },
    {
      name: "Phone",
      selector: "phone",
    },
    {
      name: "Action",
      cell: (row) => (
        <div className="d-flex gap-2">
          <button onClick={() => removeFunction(row.id)} className="btn btn-sm btn-danger">Delete</button>
          <button onClick={() => navigate(`/employee/edit/${row.id}`)} className="btn btn-sm btn-primary">Edit</button>
          <button onClick={() => navigate(`/employee/detail/${row.id}`)} className="btn btn-sm btn-success">Details</button>
        </div>
      ),
    },
  ];

  const filteredData = empdata.filter((emp) => emp.name.toLowerCase().includes(search.toLowerCase()));

  return (
    <>
      <Header title="Employee Listing" />
      <div className="container">
        <div className="row">
          <div className="col-sm-12">
            {loading && <p className="text-center">Loading employee details...</p>}
            <DataTable
              title="Employee List"
              columns={columns}
              data={filteredData}
              pagination
              fixedHeader
              selectableRows
              selectableRowsHighlight
              highlightOnHover
              actions={
                <>
                  <Link to="/employee/create" className="btn btn-sm btn-primary btn-success">Add New</Link>
                  <button className="btn btn-sm btn-primary btn-danger">Export</button>
                </>
              }
              subHeader
              subHeaderComponent={
                <input
                  type="text"
                  className="form-control w-25"
                  placeholder="Search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              }
            />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default EmployListing;
