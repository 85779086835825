import React from 'react'

const Header = (props) => {
  return (
    <>
            <div className="container-fluid mb-5">
        <div className="row bg-primary py-4">
          <div className="col-sm-12">
            <h4 className="text-uppercase text-center text-white">{props.title}</h4>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
