import { BrowserRouter, Route, Routes } from "react-router-dom"
import EmployListing from "./components/EmployListing";
import EmpCreate from "./components/EmpCreate";
import EmpDetails from "./components/EmpDetails";
 import EmpEdit from "./components/EmpEdit";
import Display from "./components/Display";


function App() {
  return (
    <div className="App">
      
<BrowserRouter>
      <Routes>

      <Route path="/" element={<EmployListing></EmployListing>}> </Route>
     <Route path="/employee/Create" element={<EmpCreate></EmpCreate>}></Route>
      <Route path="/employee/detail/:empid" element={<EmpDetails></EmpDetails>}></Route>
      <Route path="/employee/edit/:empid" element={<EmpEdit></EmpEdit>}></Route> 
      <Route path="/employee/Display/" element={<Display></Display>}></Route> 

      </Routes>
    
    </BrowserRouter>
     
    </div>
  );
   
}

export default App;
