import React, { useState, useEffect } from 'react';

const Display = () => {
    const [data, setData] = useState([]);
    const [fdata, setfData] = useState([]);
    const [search1, setSearch1] = useState('');
    const [loading, setLoading] = useState(true); // Set loading state initially to true

    useEffect(() => {
        fetch('https://api.jsonbin.io/v3/b/662650e2e41b4d34e4e872c4/records', {
            method: 'GET',
            headers: {
                'X-Master-Key': '$2a$10$wvc66GTDgrTaaR/ro4xGDuVon/g0EE7rWbLmqqBJ/Zp6zagvR7XGG',
            },
        })
        .then((resp) => resp.json())
        .then((response) => {
            const filteredData = response.record.filter((item) => item.active);
            setData(filteredData);
            setfData(filteredData);
        })
        .catch((error) => {
            console.error('Error fetching data:', error);
        })
        .finally(() => {
            setLoading(false); // Set loading state to false after fetching data
        });
    }, []);

    useEffect(() => {
        const result = data.filter((abc) => {
            return abc.name.toLowerCase().includes(search1.toLowerCase());
        });
        setfData(result);
    }, [search1, data]);

    return (
        <>
            <div className="container">
                <div className="col-sm-8 py-5 offset-2">
                    {loading ? <p>Loading Data...</p> : (
                        <table className='table table-bordered'>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>Designation</th>
                                    <th>Email</th>
                                    <th>Phone</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={4}>
                                        <input
                                            type="text"
                                            className='float-end form-control w-25'
                                            placeholder='Search'
                                            value={search1}
                                            onChange={(e) => setSearch1(e.target.value)}
                                        />
                                    </td>
                                </tr>
                                {fdata.map((item, i) => (
                                    <tr key={i}>
                                        <td>{item.name}</td>
                                        <td>{item.designation}</td>
                                        <td>{item.email}</td>
                                        <td>{item.phone}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                </div>
            </div>
        </>
    );
};

export default Display;