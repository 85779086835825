import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

const EmpEdit = () => {
    const [id, setId] = useState();
    const [name, setName] = useState("");
    const [designation, setDesig] = useState("");
    const [email, setEmail] = useState("");
    const [phone, setPhone] = useState("");
    const [active, setActive] = useState(true);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { empid } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios.get(`https://api.jsonbin.io/v3/b/662650e2e41b4d34e4e872c4/records/${empid}`, {
                    headers: {
                        "X-Master-Key": "$2a$10$wvc66GTDgrTaaR/ro4xGDuVon/g0EE7rWbLmqqBJ/Zp6zagvR7XGG",
                        "X-Access-Key": "$2a$10$u6RhiQQVn4VIvbnn8ild0.fDV/QPWDrM46pKMYnxgZYOhUKyI4eem",
                        "Access-Key-Id": "66264e96acd3cb34a83c9c97"
                    },
                });
                const data = response.data.record;
                setId(data.id);
                setName(data.name);
                setDesig(data.designation);
                setEmail(data.email);
                setPhone(data.phone);
                setActive(data.active);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [empid]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const editedEmployee = { id, name, designation, email, phone, active };

        try {
            const response = await axios.put(`https://api.jsonbin.io/v3/b/662650e2e41b4d34e4e872c4/records/${empid}`, editedEmployee, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Master-Key": "$2a$10$wvc66GTDgrTaaR/ro4xGDuVon/g0EE7rWbLmqqBJ/Zp6zagvR7XGG",
                    "X-Access-Key": "$2a$10$u6RhiQQVn4VIvbnn8ild0.fDV/QPWDrM46pKMYnxgZYOhUKyI4eem",
                    "Access-Key-Id": "66264e96acd3cb34a83c9c97"
                },
            });

            if (response.status === 200 || response.status === 201) {
                alert("Data Saved");
                navigate("/");
            } else {
                throw new Error("Failed to update employee details");
            }
        } catch (error) {
            console.error(error.message);
        }
    };

    return (
        <>
            <Header title="Edit Employee Details" />
            <form onSubmit={handleSubmit}>
                <div className="container mt-4">
                    {error && <p className="text-danger">{error}</p>}
                    {loading && <p className="text-center">Loading employee details...</p>}
                    <div className="col-sm-6 offset-3">
                        {/* Form Fields */}
                    </div>
                </div>
            </form>
            <Footer />
        </>
    );
};

export default EmpEdit;
