import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import Header from "./Header";
import Footer from "./Footer";

const EmpDetails = () => {
    const [empdata, setEmpData] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { empid } = useParams();

    useEffect(() => {
        const fetchEmployeeDetails = async () => {
            try {
                const response = await axios.get(`https://api.jsonbin.io/v3/b/662650e2e41b4d34e4e872c4/records/${empid}`, {
                    headers: {
                        "X-Master-Key": "$2a$10$wvc66GTDgrTaaR/ro4xGDuVon/g0EE7rWbLmqqBJ/Zp6zagvR7XGG",
                        "X-Access-Key": "$2a$10$u6RhiQQVn4VIvbnn8ild0.fDV/QPWDrM46pKMYnxgZYOhUKyI4eem",
                        "Access-Key-Id": "66264e96acd3cb34a83c9c97"
                    },
                });
                setEmpData(response.data.record);
            } catch (error) {
                setError(error.message);
            } finally {
                setLoading(false);
            }
        };

        fetchEmployeeDetails();
    }, [empid]);

    return (
        <>
            <Header title="Employee Details" />
            <div className="container mt-4 inner-pg">
                <div className="row">
                    <div className="col-sm-6 offset-3">
                        {loading && <p>Loading employee details...</p>}
                        {error && <p className="text-danger">{error}</p>}
                        {empdata && (
                            <div className="card">
                                <div className="card-header">
                                    <h4 className="my-0 py-0">{empdata.name} ({empdata.id})</h4>
                                </div>
                                <div className="card-body">
                                    <p><b>Designation:</b> {empdata.designation}</p>
                                    <p><b>Email:</b> {empdata.email}</p>
                                    <p><b>Phone:</b> {empdata.phone}</p>
                                    <p><Link to="/" className="btn btn-sm btn-primary btn-success">Back</Link></p>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default EmpDetails;
